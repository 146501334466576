import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css"; // Optional: Add custom styles
import BannerCarousel from "./BannerCarousel ";
import { Link } from "react-router-dom";
import Fixture from "./Fixture";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import Standing from "./Standing";
import News from "./News";
import AOS from "aos";
import "aos/dist/aos.css";
import MobileBanner from "./MobileBanner";
import banner0mg from "./stadium.svg";
import quote1 from "./qute.svg";
import newimg from "./manjeet chhillar 2.svg";
import Ipkltv from "./Ipkltv";
const PlayerCard = ({ player }) => {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Indian Premier Kabaddi League (IPKL) - Redefining Kabaddi in India
        </title>
        <link rel="canonical" href="https://ipkl.in" />
        <meta
          name="description"
          content="Join the excitement of IPKL 2024 at the Tau Devi Lal Stadium, Panchkula! Discover top kabaddi players, thrilling matches, and the latest updates from the Indian Premier Kabaddi League. Experience Kabaddi 2024 like never before."
        />
        <meta
          name="keywords"
          content=" IPKL 2024 Indian Premier Kabaddi League Kabaddi Players Tau Devi Lal Stadium Panchkula kabaddi 2024"/>
      </Helmet>
      <div className="topdivider"></div>
      <BannerCarousel />
      <MobileBanner />
      <Fixture />
      {/* <Player/>   */}
      <Standing />
      <section className="foundernew-message">
        <div className="container-fluid">
          <div className="cardinside">
            <div className="row">
              <div className="col-12 col-md-5 align-self-center p-0">
                <div className="left-img-amb">
                  <img src={newimg} className="img-fluid img1 p-0" />
                </div>
              </div>
              <div className="col-12 col-md-7 align-self-center">
                <img src={quote1} className="img-fluid quotatnew" />
                <br></br>
                <div className="right-para">
                  <p>
                    Manjeet Chhillar, a former professional kabaddi player,
                    represented the Indian national team and achieved remarkable
                    success, including winning gold medals at the 2014 Asian
                    Games and the 2014 Asian Indoor Games in Incheon. Following
                    his illustrious career, he now serves as the brand
                    ambassador for the Indian Primier Kabaddi League (IPKL) and
                    continues to guide and mentor upcoming players in the sport.
                  </p>
                </div>
                <div className="designation">
                  <div className="width-fit">
                    <h6>Manjeet Chillar</h6>

                    <div className="coloryellowstip"></div>
                  </div>
                  <p>Brand Ambassador | IPKL</p>
                  <div className="socialicone">
                    <ul className="d-flex justify-content-end list-unstyled">
                      <li>
                        <Link
                          to="https://www.facebook.com/manjeetchhillarkabaddi/?locale=hi_IN"
                          target="_blank"
                        >
                          <i
                            class="fa fa-facebook-square"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="https://www.instagram.com/manjeetchhillar/?hl=en"
                          target="_blank"
                        >
                          <i class="fa fa-instagram" aria-hidden="true"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <img src={banner0mg} className="img-fluid stadiumimg" />
      <Ipkltv />
      <News />
    </>
  );
};

export default PlayerCard;
