import React, { useState, useEffect } from "react";
import Header from "./Header";
import Breadcumb from "./Breadcumb";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import cardarrow from "./cardarrow.png";
import { Link } from "react-router-dom";
export default function Team() {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });

    // Fetching the team data from the API using fetch
    const fetchTeams = async () => {
      try {
        const response = await fetch(
          "https://dev-ipkl.ipkl.in/api/resource/Teams%20Card?fields=[%22*%22]"
        );
        const data = await response.json(); // Parse the JSON from the response
        const sortedData = data.data.sort((a, b) => a.position - b.position);

        setTeams(sortedData); // Assuming the API returns data in `data.data`
      } catch (error) {
        console.error("Error fetching team data", error);
      }
    };

    fetchTeams();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Indian Premier Kabaddi League (IPKL) - Meet the Teams and Players
        </title>
        <link rel="canonical" href="https://ipkl.in/team" />
        <meta
          name="description"
          content="Get all the details on IPKL teams and kabaddi players. Find comprehensive team profiles and player stats for the 2024 season."
        />
        <meta
          name="keywords"
          content=" 
  IPKL Teams
  IPKL players
  IPKL kabaddi Players
  IPKL Team Details
  Tau Devi Stadium panchkula
  "
        />
      </Helmet>

      <Breadcumb page="Teams" title="Meet our IPKL Team 2024: Ready To Win" />

      <section id="teamsection">
        <div className="container-fluid">
          <div className="row">
            {teams.length > 0 ? (
              teams.map((team, index) => (
                <div key={index} className="col-md-4 col-12">
                 <div className="teamnewcard">
                 <div
                    className={`team-card-bengaluru-bison ${team.team_name}`}
                    style={{
                      backgroundImage: team.background_image
                        ? `url(https://dev-ipkl.ipkl.in/${team.background_image})`
                        : "none",
                    }}
                  >
                    <div class="team-card-content">
                      <div className="widthfit">
                      <h2 class="team-card-heading">{team.team_name}</h2>
                      <div className="linecard"></div>
                      </div>
                      <div class="team-card-buttons">
                      {/* <Link to={`/players?id=${team.team_name}`}> */}
                   <button class="team-card-btn team-card-matches-btn">
                          Matches
                        </button>
                        {/* </Link> */}
                        <button class="team-card-btn team-card-profile-btn">
                          View Profile
                        </button>
                      </div>
                    </div>
                    <div class="team-card-logo-corner">
                      <img
                        src={`https://dev-ipkl.ipkl.in/${team.team_icon}`}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo"
                      />

                      <img
                        src={`https://dev-ipkl.ipkl.in/${team.abstract_arrow}`}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo1"
                      />
                    </div>
                  </div>
                 </div>
                </div>
              ))
            ) : (
              <p>Loading teams...</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
