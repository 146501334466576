import React, { useEffect, useState } from "react"; 
import img from './IMP.jpg';
import close from './cross-button.png';

const ModalComponent = () => {
  const [showModal, setShowModal] = useState(false); // Initially hidden
  const [fadeType, setFadeType] = useState(""); // To control the fade-in and fade-out animations

  useEffect(() => {
    // Show modal with fade-in after 3 seconds
    const showTimer = setTimeout(() => {
      setFadeType("in");
      setShowModal(true);
    }, 3000);

    // Hide modal with fade-out after 8 seconds (3s + 8s)
    const hideTimer = setTimeout(() => {
      setFadeType("out");
      setTimeout(() => {
        setShowModal(false);
      }, 1000); // Match the animation duration for fade-out
    }, 70000000); // 3s delay + 8s = 11s

    // Cleanup timers on component unmount
    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
      document.body.style.overflow = 'auto'; // Re-enable scrolling if component unmounts
    };
  }, []);

  useEffect(() => {
    // Disable scrolling on modal open
    if (showModal && fadeType === "in") {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showModal, fadeType]);

  const handleClose = () => {
    setFadeType("out");
    setTimeout(() => {
      setShowModal(false);
    }, 1000); // Match the animation duration
  };

  // Handle click outside the modal content
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('modal')) {
      handleClose();
    }
  };

  return (
    <>
      {showModal && (
        <div
          className={`modal fade ${fadeType === "in" ? "fade-in" : fadeType === "out" ? "fade-out" : ""} show d-block`}
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
          onClick={handleOutsideClick}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={handleClose}
              >
                <img src={close} className="img-fluid" alt="Close button"/>
              </button>
              <div className="modal-body p-0">
                <img src={img} className="img-fluid" alt="Popup" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalComponent;
