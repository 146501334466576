import React, { useState, useEffect } from "react";
import Breadcumb from "./Breadcumb";
import { Helmet } from "react-helmet";
export default function Matches() {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const now = new Date();
  const currentDay = now.getDate();
  const currentMonth = now.getMonth() + 1; 
  const currentYear = now.getFullYear();

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        const response = await fetch(
          `https://dev-ipkl.ipkl.in/api/resource/FIXTURES?limit_page_length=100&fields=["*"]&order_by=match_number DESC`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const sortedData = data.data.sort(
          (a, b) => a.match_number - b.match_number
        );
        setMatches(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchMatches();
  }, []);

  const groupMatchesByDate = (matches) => {
    return matches.reduce((acc, match) => {
      const matchDate = new Date(match.match_date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      if (!acc[matchDate]) {
        acc[matchDate] = [];
      }
      acc[matchDate].push(match);
      return acc;
    }, {});
  };

  const groupedMatches = groupMatchesByDate(matches);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Watch IPKL Kabaddi Matches Live - Upcoming Games and Schedule
        </title>
        <link rel="canonical" href="https://ipkl.in/team" />
        <meta
          name="description"
          content="Watch IPKL kabaddi matches live and stay informed about upcoming games. Access the latest match schedule and updates for the Indian Premier Kabaddi League."
        />
        <meta
          name="keywords"
          content="Upcoming kabaddi matches IPKL matches today kabaddi live match IPKL Kabaddi matches kabaddi matches"
        />
      </Helmet>

      <Breadcumb page="Matches" title="Revealing IPKL 2024 matches and teams" />
      <section id="matchbg">
        <div className="container-fluid">
          {loading ? (
            <p className="text-white">Loading...</p>
          ) : (
            Object.keys(groupedMatches).map((date) => {
              const matchDate = new Date(date);
              const weekdayOptions = { weekday: "long" };
              const weekday = matchDate.toLocaleDateString(
                "en-US",
                weekdayOptions
              );

              return (
                <React.Fragment key={date}>
                  <div className="headingsection">
                    {/* <h4 className="text-white">
                      {date === "October 18, 2024"
                        ? "Semi Final Match | October 18, 2024"
                        : date === "October 19, 2024"
                        ? "Final Match | October 19, 2024"
                        : `${weekday} |  ${date}`}
                    </h4> */}
                    {/* <div className="lineyellowhighlight"></div> */}
                  </div>
                  <div className="row">
                    {groupedMatches[date].map((match, index) => (
                      <div className="col-md-6 col-12" key={index}>
                        <div className="matches-box">
                          <div className="top-box-match">
                            <div className="row">
                              <div className="col-md-8 col-7">
                                <div className="stadium-name">
                                  <p>
                                    <i
                                      class="fa fa-map-marker"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    &nbsp; Tau Devi Lal Stadium, Panchkula,
                                    Haryana
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4 col-5">
                                <div className="stadium-name">
                                  <p className="text-right">
                                    <i
                                      class="fa fa-clock-o"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;Time : XX:XX
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="bottom-box-match">
                            <div className="row">
                              <div className="col-3 align-self-center">
                                <img
                                  src={`https://dev-ipkl.ipkl.in/${match.team_a_logo}`}
                                  className="img-fluid"
                                  alt="Team A Logo"
                                />
                                <p className="paramatches">
                                  {match.team_a_name}
                                </p>
                              </div>

                              <div className="col-6 col-md-6 align-self-center">
                                <div className="center-match-box">
                                  {/* <h4 className="weekday"> {weekday}</h4> */}
                                  {/* <p>Day {match.match_day} |  {date} </p> */}
                                  <div className="countdown-card">
                                    <div className="separator">VS</div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-3 align-self-center">
                                <img
                                  src={`https://dev-ipkl.ipkl.in/${match.team_b_logo}`}
                                  className="img-fluid"
                                  alt="Team B Logo"
                                />
                                <p className="paramatches">
                                  {match.team_b_name}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              );
            })
          )}
        </div>
      </section>
    </>
  );
}
