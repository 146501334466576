import React, { useState } from 'react';
// import logo from "./logo.png";
import logo from  './Logo png.png'
import { NavLink } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import i1 from './twemoji_stadium.png'
import close from './cross-button.png'
export default function Header() {

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const { pathname } = useLocation();
const location = useLocation();

  return (
    <>
      <header>
        <div className="container-fluid bg-yellow">
          <div className="row">
            <div className="col-c1 align-self-center">
              <Link class="navbar-brand" to="/">
                <img src={logo} alt="Logo" className="logo" />
              </Link>
            </div>
            <div className="col-c2 p-0">
              <div class="preheader">
                <div className="row bg-blue">
                  <div className="col-8 col-md-6">
                    <div className="d-flex">
                      <div className="marqueemove">
                        <p className="marquee marque d-sm-none d-md-block">
                        Indian Premier Kabaddi League 2024 kicks off  &nbsp;&nbsp;
                          &nbsp;&nbsp;
                          {/* <i class="fa fa-circle" aria-hidden="true"></i>
                          &nbsp;&nbsp;&nbsp;&nbsp; Catch the IPKL 2024 action live at Panchkula’s Tau Devi Lal Stadium, October xth to xth
                          &nbsp;&nbsp;
                          <i class="fa fa-circle" aria-hidden="true"></i>
                          &nbsp;&nbsp;&nbsp;&nbsp; Eight teams, one epic showdown: IPKL 2024 brings together the best players from around the globe!
 */}





                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-6 align-self-center d-sm-none d-md-block">
                    <div className="whitebox">
                      <p>
                        {/* <img src={i1} className="img-fluid"/> */}
                       
                    
<svg width="23" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.9369 6.72266L0.0351105 6.5791V11.8329C0.0399994 12.3147 0.483555 12.7751 1.15511 13.1787L2.22 13.6822C2.54563 13.8058 2.90015 13.9192 3.28355 14.0227L4.87689 14.3613C5.22844 14.4178 5.59467 14.4644 5.97022 14.5035L10.116 14.5427C10.4911 14.5112 10.8651 14.469 11.2378 14.416L12.8244 14.1018C13.1819 14.0112 13.5346 13.9029 13.8813 13.7773L14.9364 13.2893C15.6062 12.8924 15.9418 12.4347 15.9369 11.9502V6.72266Z" fill="#CCD6DC"/>
<path d="M10.1711 12.7103C10.152 10.7725 9.19777 10.2792 8.02666 10.2689C6.856 10.2583 5.91155 10.7343 5.93066 12.672C5.93777 13.3858 5.95111 13.9876 5.97022 14.5032C6.63866 14.5725 7.33866 14.616 8.06444 14.6227C8.77555 14.6289 9.46044 14.5987 10.116 14.5427C10.1591 14.0969 10.1791 13.5023 10.1711 12.7103ZM4.85244 11.8876L4.85377 12.0494L4.85511 12.1698L4.85555 12.1987C4.856 12.2218 4.85555 12.2183 4.85555 12.1987L4.85511 12.1698L4.85377 12.0494L4.85244 11.8876ZM4.852 11.84L4.85155 11.7858C4.84177 10.8169 3.24711 10.3178 3.25644 11.2872V11.3036L3.25733 11.3943L3.25822 11.4645L3.26044 11.6734L3.26089 11.7032L3.28355 14.0223C3.77511 14.1543 4.30666 14.2694 4.87689 14.3609L4.852 11.804V11.84ZM12.8 11.624V11.636L12.8013 11.756C12.8018 11.8227 12.8018 11.8085 12.8013 11.756L12.8 11.636V11.624ZM12.7978 11.3992L12.7973 11.3734C12.7876 10.4045 11.2022 10.8743 11.212 11.8432V11.8592L11.2373 14.416C11.7713 14.3389 12.3009 14.234 12.824 14.1018L12.7973 11.3907L12.7978 11.3992ZM2.19155 10.7929C2.18177 9.82404 1.11733 9.32982 1.12666 10.2987V10.3169L1.15466 13.1787C1.45511 13.3592 1.81377 13.5276 2.21955 13.6823L2.19644 11.3174C2.19777 11.4098 2.19911 11.576 2.19155 10.7929ZM13.8524 10.8983L13.8578 11.4036L13.8813 13.7769C14.284 13.6285 14.6391 13.4654 14.9364 13.2889L14.908 10.4232C14.8987 9.45427 13.8431 9.92938 13.8524 10.8983ZM1.32089 1.32227C1.26195 1.32227 1.20543 1.34568 1.16375 1.38735C1.12208 1.42903 1.09866 1.48555 1.09866 1.54449V7.04227C1.09866 7.1012 1.12208 7.15773 1.16375 7.1994C1.20543 7.24107 1.26195 7.26449 1.32089 7.26449C1.37982 7.26449 1.43635 7.24107 1.47802 7.1994C1.5197 7.15773 1.54311 7.1012 1.54311 7.04227V1.54449C1.54311 1.42138 1.444 1.32227 1.32089 1.32227Z" fill="#66757F"/>
<path d="M1.42533 1.63818C2.08222 1.75774 3.06178 2.12796 3.31245 2.27107C3.56311 2.41418 3.408 2.48618 3.16933 2.59329C2.93022 2.70085 1.64 3.04707 1.44933 3.08307C1.25778 3.11952 1.42533 1.63818 1.42533 1.63818Z" fill="#DD2F45"/>
<path d="M5.31956 0.322632C5.26062 0.322632 5.2041 0.346044 5.16242 0.387719C5.12075 0.429394 5.09734 0.485917 5.09734 0.544854V6.04263C5.09734 6.10157 5.12075 6.15809 5.16242 6.19977C5.2041 6.24144 5.26062 6.26485 5.31956 6.26485C5.3785 6.26485 5.43502 6.24144 5.47669 6.19977C5.51837 6.15809 5.54178 6.10157 5.54178 6.04263V0.544854C5.54178 0.485917 5.51837 0.429394 5.47669 0.387719C5.43502 0.346044 5.3785 0.322632 5.31956 0.322632Z" fill="#66757F"/>
<path d="M5.42356 0.638672C6.08045 0.758227 7.06 1.12845 7.31111 1.27201C7.56178 1.41512 7.40667 1.48712 7.168 1.59423C6.92889 1.70178 5.63911 2.04801 5.448 2.08401C5.256 2.12001 5.42356 0.638672 5.42356 0.638672Z" fill="#226798"/>
<path d="M9.484 0.322632C9.42507 0.322632 9.36854 0.346044 9.32687 0.387719C9.28519 0.429394 9.26178 0.485917 9.26178 0.544854V6.04263C9.26178 6.10157 9.28519 6.15809 9.32687 6.19977C9.36854 6.24144 9.42507 6.26485 9.484 6.26485C9.54294 6.26485 9.59946 6.24144 9.64114 6.19977C9.68281 6.15809 9.70622 6.10157 9.70622 6.04263V0.544854C9.70622 0.485917 9.68281 0.429394 9.64114 0.387719C9.59946 0.346044 9.54294 0.322632 9.484 0.322632Z" fill="#66757F"/>
<path d="M9.58844 0.638672C10.2453 0.758227 11.2244 1.12845 11.4756 1.27201C11.7262 1.41512 11.5711 1.48712 11.3324 1.59423C11.0938 1.70178 9.80355 2.04801 9.612 2.08401C9.42044 2.12001 9.58844 0.638672 9.58844 0.638672Z" fill="#DD2F45"/>
<path d="M13.4822 1.32227C13.4233 1.32227 13.3668 1.34568 13.3251 1.38735C13.2834 1.42903 13.26 1.48555 13.26 1.54449V7.04227C13.26 7.1012 13.2834 7.15773 13.3251 7.1994C13.3668 7.24107 13.4233 7.26449 13.4822 7.26449C13.5412 7.26449 13.5977 7.24107 13.6394 7.1994C13.681 7.15773 13.7044 7.1012 13.7044 7.04227V1.54449C13.7044 1.48555 13.681 1.42903 13.6394 1.38735C13.5977 1.34568 13.5412 1.32227 13.4822 1.32227Z" fill="#66757F"/>
<path d="M13.5867 1.63818C14.2436 1.75774 15.2227 2.12796 15.4738 2.27152C15.7244 2.41463 15.5693 2.48663 15.3307 2.59374C15.092 2.70129 13.8018 3.04752 13.6102 3.08352C13.4191 3.11952 13.5867 1.63818 13.5867 1.63818Z" fill="#226798"/>
<path d="M15.9369 6.75289C15.9516 8.22489 12.404 9.38577 8.01288 9.34622C3.62177 9.30666 0.0497747 8.08044 0.0355525 6.60933C0.0208858 5.13733 3.56889 3.97644 7.96 4.016C12.3511 4.05555 15.9222 5.28133 15.9369 6.75289Z" fill="#E9EFF3"/>
<path d="M14.7809 7.09287C14.7938 8.42399 11.764 9.47554 8.01378 9.44221C4.26266 9.40799 1.21155 8.30132 1.19866 6.97021C1.18578 5.6391 4.21511 4.58799 7.96622 4.62176C11.716 4.65599 14.768 5.76221 14.7809 7.09287Z" fill="#7450A0"/>
<path d="M11.896 7.61241L14.4871 5.86174L13.6871 5.37018C13.6871 5.37018 12.0738 6.87863 11.544 7.39152C11.1547 7.18929 10.6227 7.01952 9.99467 6.90085L11.0787 4.54396L9.984 4.41329L9.49778 6.82174C8.99672 6.75378 8.49186 6.71756 7.98622 6.71329C7.59 6.71009 7.19384 6.72581 6.79911 6.76041L6.27334 4.37952L5.18134 4.49107L6.28845 6.81774C5.55956 6.91818 4.93867 7.08618 4.49467 7.29996C3.92978 6.75463 2.34045 5.26752 2.34045 5.26752L1.55022 5.74485L4.13422 7.51374C3.89378 7.69018 3.75778 7.88707 3.75956 8.0964C3.76756 8.88129 5.672 9.53507 8.01422 9.55641C10.3556 9.57774 12.2484 8.95818 12.2409 8.17285C12.2391 7.97507 12.116 7.78529 11.896 7.61241Z" fill="#E9EFF3"/>
<path d="M11.7453 8.27196C11.7511 8.85907 10.0867 9.31952 8.02845 9.30085C5.97023 9.28219 4.29689 8.79152 4.29111 8.20441C4.28534 7.61774 5.94934 7.1573 8.00756 7.17596C10.0653 7.19419 11.7391 7.6853 11.7453 8.27196Z" fill="#5C903F"/>
</svg>
&nbsp;
&nbsp;
   Tau Devi Lal Stadium, Panchkula, Haryana | India

                       
                      </p>
                    </div>
                  </div>

                  <div className="col-md-2 col-4 align-self-center">
                    <div className="socialicone">
                    <ul className="d-flex">
                        <li>
                          <Link
                            to="https://www.facebook.com/indianpremierkabaddileague/"
                            target="_blank"
                          >
                            <i
                              class="fa fa-facebook-square"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link
                            to="https://www.instagram.com/ipklofficial/"
                            target="_blank"
                          >
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </Link>
                        </li>
                        <li className="">
                        <Link
                            to="https://www.linkedin.com/showcase/indian-premier-kabaddi-league/"
                            target="_blank"
                          >
                          <i class="fa fa-linkedin " aria-hidden="true"></i>
                          </Link>
                        </li>

                        <li>
                        <Link
                            to="https://www.youtube.com/@IndianPremiereKabaddiLeague"
                            target="_blank"
                          >
                          <i class="fa fa-youtube-play" aria-hidden="true"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <nav className={`navbar navbar-expand-lg navbar-dark bg-yellow`}>
  <div className="container-fluid">
    <Link className="navbar-brand" to="/">
      <img src={logo} alt="Logo" className="logosm" />
    </Link>
    <button
      className="navbar-toggler"
      type="button"
      onClick={toggleNavbar}
      aria-controls="navbarSupportedContent"
      aria-expanded={isOpen}
      aria-label="Toggle navigation"
    >
      <span className={`navbar-toggler-icon ${isOpen ? 'active' : ''}`}></span>
    </button>
    <div
      className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}
      id="navbarSupportedContent"
    >
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <Link
            className={location.pathname === '/' ? 'active nav-link' : 'nav-link'}
            to="/"
            onClick={toggleNavbar} // Close sidebar on click
          >
            Home
          </Link>
        </li>

        <li className="nav-item">
          <NavLink
            className="nav-link"
            to="/about"
            activeClassName="active"
            onClick={toggleNavbar} // Close sidebar on click
          >
            About IPKL
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            className="nav-link"
            to="/teams"
            activeClassName="active"
            onClick={toggleNavbar} // Close sidebar on click
          >
            Teams
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            className="nav-link"
            to="/matches"
            activeClassName="active"
            onClick={toggleNavbar} // Close sidebar on click
          >
            Matches
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            className="nav-link"
            to="/standings"
            activeClassName="active"
            onClick={toggleNavbar} // Close sidebar on click
          >
            Standings
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            className="nav-link"
            to="/blog"
            activeClassName="active"
            onClick={toggleNavbar} // Close sidebar on click
          >
            Blogs
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            className="nav-link"
            to="/news"
            activeClassName="active"
            onClick={toggleNavbar} // Close sidebar on click
          >
            News & Articles
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            className="nav-link"
            to="/gallery"
            activeClassName="active"
            onClick={toggleNavbar} // Close sidebar on click
          >
            Gallery
          </NavLink>
        </li>

    
        <li className="nav-item">
          <NavLink
            className="nav-link"
            to="/contact"
            activeClassName="active"
            onClick={toggleNavbar} // Close sidebar on click
          >
            Contact Us
          </NavLink>
        </li>
      </ul>
      {isOpen && (
        <>
          <button className="close-btn" onClick={toggleNavbar}>
        <img src={close} className='img-fluid' />
          </button>
        </>
      )}
    </div>
  </div>
</nav>

            </div>
          </div>
        </div>
      </header>




      
    </>
  );
}
